<template>
  <div>
      <base-modal id="passcodeInput" v-bind:is-visible="isVisible" v-on:update:isVisible="close()">
        <div class="p-5">
            <form  @submit.prevent="submit()">
			<div class="form-group">
                <label for="passcode" class="text-left w-full font-bold">Please enter the one time passcode you have recieved in your email to proceed. Please reach out to us, if you are facing any difficulties.</label>
				<input class="form-control" v-model="passcode" placeholder="" title="Enter the seven digit passcode" type="passcode" :class="{ 'is-invalid': $v.passcode.$error }" />
			</div>
			<div class="text-left mt-4">
				<base-button class="btn btn-primary" :isConfirm="true" :state="submitButtonState" :isBorderRadiud="false">Proceed</base-button>
			</div>
		</form>
        </div>
      </base-modal>
      
  </div>
</template>

<script>
import { required, minLength,maxLength } from "vuelidate/lib/validators";
import ButtonState from '../../lib/button-state';
import PasscodeService from '../../services/PasscodeService';
import MessageService from '../../services/MessageService';
import BaseButton from '../BaseButton.vue';
import BaseModal from '../BaseModal.vue';
import SessionService from '../../services/SessionService';

export default {
  components: { 
        BaseModal,
        BaseButton,
    },
    data() {
			return {
                emailAddress : SessionService.getClientEmaiId(),
				passcode: null,
                submitButtonState: ButtonState.Ready
			};
        },
        validations: {
			passcode: {required, minLength: minLength(7), maxLength: maxLength(7)},
        },
        methods: {
            close() {
				if (this.submitButtonState === ButtonState.Loading) { return; }
                this.$emit('close');
			},
            submit() {
                
                this.$v.$touch();
                if(this.$v.$invalid) {return;}
                this.submitButtonState = ButtonState.Loading;
                SessionService.setPasscode(this.passcode);

                // this.$emit('valid-passcode',{ cb: () => { this.close()}} );
                //         SessionService.setPasscode(this.passcode)
                // this.submitButtonState = ButtonState.Ready

                let passcodeObject = {
                    email_id: this.email,
                    passcode: this.passcode

                };
                PasscodeService.validatePasscode(passcodeObject)
                .then((res) => {
                    let response = res.data;
                    if(response.response_code === 0) {
                        MessageService.show(response.description);
                        this.submitButtonState = ButtonState.Complete;
                        this.$emit('invalid-passcode',{ cb: () => { this.close()}} );
                        window.localStorage.setItem("clientEmailId","");
                        window.localStorage.setItem("passcode","");
                    }
                    else {
                        this.submitButtonState = ButtonState.Ready
                        MessageService.show(response.description);
                        this.$emit('valid-passcode',{ cb: () => { this.close()}} );
                        
                        setTimeout(() => {
                            this.passcode = '';
                        }, 1000);
                        this.$nextTick(() => { this.$v.$reset(); });
                    }
                })
                .catch((err) => {
                    let errorMsg = err.message ? err.message : "Some thing went wrong, Please Try again";
                    MessageService.show(errorMsg)
                    this.submitButtonState = ButtonState.Ready;
                });
            }
        },
        props: {
            isVisible: Boolean,
            email:String
        }
}
</script>

<style lang="scss" scoped>
.btn {
    width: 220px !important;
    height: 60px !important;
    font-size: 1.2em !important;
}
</style>