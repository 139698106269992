<template>
  <div>
    <div class="details-card mt-5">
      <div
        class="sm:p-8 overflow-hidden rounded-xl shadow-xl bg-white hover:shadow-2xl transition-shadow duration-300 ease-in-out"
      >
        <div class="card-section-top">
          <div class="flex items-center h-14">
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-once="true"
              class="card-title"
            >{{title}}</div>
          </div>
          <hr
            class="my-0 border-line"
            data-aos="fade-right"
            data-aos-delay="350"
            data-aos-once="true"
          />
        </div>

        <div class="my-1">
          <div class="leading-none p-2 md:p-4 overflow-hidden">
            <div class="card-text">
              <p
                data-aos="fade-up"
                data-aos-delay="350"
                data-aos-once="true"
                class="has-font-circular-book mt-2 pb-5 h-14"
              >{{description}}</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="400" data-aos-once="true" class="product-link">
            <router-link :to="to" target="_blank">Try Now</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    textHeight: String,
    to: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";
.details-card {
  @media (max-width: 500px) {
    width: 10rem;
    div {
      padding: 4px;
    }
  }

  .product-link {
    font-size: $font-size-14px;
  }
}
// .card-section-top {
//   border-bottom: 1px solid #707070;
// }

.card-title {
  font-size: 1.2em;
  font-weight: bold;
  @media (max-width: 770px) {
    font-size: 0.8em;
  }
}

.card-text {
  font-size: 0.8em;
  color: $gray-dark;
  line-height: 1.5;

  @media (max-width: 500px) {
    font-size: 0.6em;
  }
}
</style>