import Axios from 'axios';

import Promise from 'es6-promise';


const AiApiService = Axios.create({
	baseURL: 'https://magureincapis.fostersoftwares.com/'
});

AiApiService.refreshHeaders = () => {
	AiApiService.defaults.headers.common['Accept'] = 'application/json';
	AiApiService.defaults.headers.common['Content-Type'] = 'application/json';
};
AiApiService.refreshHeaders();

AiApiService.interceptors.response.use(function (response) {
	if (response.data && response.data.error) {
			throw new Axios.Cancel('There was an error please try again.');
	}

	return response;
}, function (error) {
	return Promise.reject(error);
});

export default AiApiService;