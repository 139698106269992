<template>
  <div class="my-3" data-aos="fade-in" data-aos-delay="200" data-aos-once="true">
    <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
      <h3 class="result-name mb-0 py-2">{{title}}</h3>
      <button class="btn btn-transparent">
        <caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
        <caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
      </button>
    </div>
    <div class="relative text-left h-auto my-4" v-if="isCollapsed">
      <p data-aos="fade-in-left" data-aos-delay="200" data-aos-once="true" class="p-2">{{answer}}</p>
    </div>
  </div>
</template>

<script>
import CaretDownIcon from "../../icons/CaretDownIcon";
import CaretUpIcon from "../../icons/CaretUpIcon.vue";
export default {
  components: {
    CaretDownIcon,
    CaretUpIcon
  },
  data() {
    return {
      isCollapsed: false
    };
  },
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    }
  },

  props: {
    title: String,
    answer: String
  }
};
</script>

<style lang="scss" scoped>
</style>