import AiApiService from './ai-api-service';

const PasscodeService = (() => {
    "use strict";

    var getPasscode;
    var validatePasscode;

    getPasscode = (data) => {
        return AiApiService.post('generate_passcode/', data)
    };

    validatePasscode = (data) => {
        return AiApiService.post('validate_passcode/', data)
    };

    return {
        getPasscode: getPasscode,
        validatePasscode:validatePasscode
    }

}) ();
export default PasscodeService;