import AiApiService from './ai-api-service';

const UrlPathService = (() => {
	'use strict';

    var faceDetect;
	var celebrityRecognition;
	var ageGenderPrediction;
	var emotionDetection;
	var objectDetection;
	var faceSpoofingDetection;
	var faceCompare;
	var weaponDetect;
	var wheelchairDetect;
	var questionAnswer;

	faceDetect = (data) => {
		return AiApiService.post('face_detection/', data);
	};

	celebrityRecognition = (data) => {
		return AiApiService.post('celebrity_recognition/',data);
	}

	ageGenderPrediction = (data) => {
		return AiApiService.post('age_gender_prediction/',data);
	}

	emotionDetection = (data) => {
		return AiApiService.post('emotion_detection/',data);
	}

	objectDetection = (data) => {
		return AiApiService.post('object_detection/',data);
	}

	faceSpoofingDetection = (data) => {
		return AiApiService.post('face_spoofing_detection/',data);
	}

	faceCompare = (data) => {
		return AiApiService.post('face_recognition/',data);
	}

	weaponDetect = (data) => {
		return AiApiService.post('weapon_detection/',data);
	}

	wheelchairDetect = (data) => {
		return AiApiService.post('wheelchair_detection/',data);
	}
	questionAnswer = (data) => {
		return AiApiService.post('question_answering/',data);
	}

	return {
		faceDetect: faceDetect,
		celebrityRecognition:celebrityRecognition,
		ageGenderPrediction: ageGenderPrediction,
		emotionDetection: emotionDetection,
		objectDetection: objectDetection,
		faceSpoofingDetection: faceSpoofingDetection,
		faceCompare: faceCompare,
		weaponDetect: weaponDetect,
		wheelchairDetect:wheelchairDetect,
		questionAnswer:questionAnswer
	};
})();

export default UrlPathService;