<template>
    <div class="pt-5">
        <div class="slide-title  my-12 md:my-18">Try other models.</div>
        <swiper class="swiper" :options="swiperOption" >
            <swiper-slide class="swiper-slide" v-for="card in applicationCards" :key="card.id" :class="card.route==currentRoute ? 'd-none' : ''">
                    <details-card
                    :title="card.title"
                    :description="card.description"
                    :to="card.route"
                    />
                
            </swiper-slide>

            <div class="swiper-pagination-H flex justify-end my-5" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
    import "swiper/swiper-bundle.min.css";
    import DetailsCard from './Products/DetailsCard.vue';
import DataServies from '../services/DataService';

export default {
    components:{
        Swiper,
        SwiperSlide,
        DetailsCard,
    },
    data() {
        return {
            applicationCards: DataServies.productsSectionData[0].productDetails,
            currentRoute: this.$route.path,
            swiperOption: {
            slidesPerView: 3,
            mousewheel: false,
            loop: false,
            pagination: {
                el: '.swiper-pagination-H',
                clickable: true
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            speed: 500,
            breakpoints: {
            1024: {
            slidesPerView: 3,
            spaceBetween: 30
            },
            768: {
            slidesPerView: 2,
            spaceBetween: 30
            },
            640: {
            slidesPerView: 2,
            spaceBetween: 15
            },
            320: {
            slidesPerView: 2,
            spaceBetween: 5
            }
            }
        }
        }
    }
}
</script>

<style>

</style>