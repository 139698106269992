<template>
  <div>
      <base-modal id="EamilInput" v-bind:is-visible="isVisible" v-on:update:isVisible="close()">
        <div class="p-5">
            <form  @submit.prevent="submit()">
			<div class="form-group">
                <label for="email" class="text-left w-full font-bold">To try the demo, please enter your email so that we can send you one time passcode. The passcode is valid for 1 hour.</label>
				<input class="form-control" v-model="emailAddress" placeholder="" title="Enter your email address" type="email" :class="{ 'is-invalid': $v.emailAddress.$error }" />
			</div>
			<div class="text-left mt-4 d-flex flex-col md:flex-row">
				<base-button class="btn btn-primary mt-2" :isConfirm="true" :state="submitButtonState" :isBorderRadiud="false">Get Passcode</base-button>
				<a class="mt-2 md:ml-2 underline d-flex items-center"  @click="goToPasscodeModal()">Already have Passcode?</a>
			</div>
            
		</form>
        </div>
      </base-modal>
      
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import ButtonState from '../../lib/button-state';
import PasscodeService from '../../services/PasscodeService';
import MessageService from '../../services/MessageService';
import BaseButton from '../BaseButton.vue';
import BaseModal from '../BaseModal.vue';
import SessionService from '../../services/SessionService';

export default {
  components: { 
        BaseModal,
        BaseButton,
    },
    data() {
			return {
				emailAddress: '',
                submitButtonState: ButtonState.Ready
			};
        },
        validations: {
			emailAddress: {required, email},
        },
        methods: {
            close() {
				if (this.submitButtonState === ButtonState.Loading) { return; }
                this.$emit('close');
			},
            submit() {
                
                this.$v.$touch();
                if(this.$v.$invalid) {return;}
                this.submitButtonState = ButtonState.Loading;

                    // this.$emit('valid-email',{ cb: () => { this.close()}} );
                    //     SessionService.setClientEmailId(this.emailAddress);

                let emailObject = {
                    email_id: this.emailAddress,
                };
                PasscodeService.getPasscode(emailObject)
                .then((res) => {
                    let response = res.data;
                    if(response.response_code === 0) {
                        MessageService.show(response.description)
                    }
                    else {
                        this.submitButtonState = ButtonState.Ready
                        MessageService.show(response.description);
                        SessionService.setClientEmailId(this.emailAddress);
                        this.$emit('valid-email',{ cb: () => { this.close()},email:this.emailAddress} );
                        this.emailAddress = '';
                        this.$nextTick(() => { this.$v.$reset(); });
                    }
                })
                .catch((err) => {
                    let errorMsg = err.message ? err.message : "Something went wrong, Please Try again.";
                    MessageService.show(errorMsg)
                    this.submitButtonState = ButtonState.Ready;
                });
            },

            goToPasscodeModal() {
                this.$v.$touch();
                if(this.$v.$invalid) {return;}
                // this.emailAddress = SessionService.getClientEmaiId();
                if(!SessionService.getIsCurrentClientGivenEmail()) {
                    SessionService.setClientEmailId(this.emailAddress);
                }
                this.$emit('valid-email',{ cb: () => { this.close()},email:this.emailAddress} );
            }
        },
        props: {
            isVisible: Boolean,
        }
}
</script>

<style lang="scss" scoped>
.btn {
    width: 220px !important;
    height: 60px !important;
    font-size: 1.2em !important;
}
.underline {
    text-decoration: underline !important;
    cursor: pointer;
}
</style>